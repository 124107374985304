
import { defineComponent } from "vue";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
//import { BooleanColumnConfig } from "@/components/controls/catalogueGrid/columns/booleanColumnConfig";
import { DecimalColumnConfig } from "@/components/controls/catalogueGrid/columns/decimalColumnConfig";
import { CatalogueColumnConfig } from "@/components/controls/catalogueGrid/columns/catalogueColumnConfig";
import planTransportBoxCarCreateEditDialog from "@/components/journals/PlanTransportJournal/TransportBoxCar/PlanTransportBoxCarCreateEditDialog.vue";
import { ability } from "@/abilities/ability";

export default defineComponent({
  components: {
    CatalogueGrid,
  },
  props: {
    //показатель режима выбора
    selectMode: { required: false, type: Boolean, default: false },
    gridHeight: { required: false, type: String, default: null },
    //функция для получения выбранных элементов
    getSelectedData: { type: Function },
    //функция для вызова при изменении выбранных элементов
    changeSelectedData: { type: Function },
    //модель родительского элемента
    parentModel: { required: true },
    //идет расчет плана
    isCalculation: { required: false },
    //открыты ли настройки
    isSettingsOpen: { required: true },
  },
  computed: {
    planTransportBoxCarCreateEditDialog(): any {
      return planTransportBoxCarCreateEditDialog;
    },
  },
  methods: {
    setColums() {
      if (ability.can("view", "Modules.Order")) {
        this.columns.push(
          new TextColumnConfig({
            dataField: "orderDescription",
            caption: "Заказ",
          })
        );
      }
    },
  },
  data() {
    return {
      // подробности работы custom summary см. на сервере в методе SetCustomSummary
      summaryConfig: {
        totalItems: [
          {
            name: "TotalSummary",
            showInColumn: "name",
            displayFormat: "Итог:",
            summaryType: "custom",
          },
          {
            name: "CountSummary",
            showInColumn: "planCount",
            column: "planCount",
            valueFormat: "#,##0.#",
            displayFormat: "{0} шт",
            summaryType: "sum",
          },
          {
            name: "LoadCapacitySummary",
            showInColumn: "loadCapacity",
            column: "totalLoadCapacity",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },
        ],
      },
      editingConfig: new TableEditingConfig({
        allowUpdating: !this.isCalculation,
        allowAdding: !this.isCalculation,
        allowDeleting: !this.isCalculation,
        confirmDelete: true,
        allowDataFill: !this.isCalculation,
        allowExport: false,
        allowImport: false,
        allowDeleteAll: !this.isCalculation,
        allowClearFilter: true,
        allowDeleteMultipleRows: true,
        allowChangeScrollSettings: true,
        allowColumnReordering: true,
        allowResetSelectedRows: true,
        chooseColumns: true,
      }),
      selectModeEditingConfig: new TableEditingConfig({
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: false,
        allowDataFill: false,
        allowExport: false,
        allowDeleteMultipleRows: false,
      }),
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        heightScroll: "calc(100vh - 350px)",
        horizontalScroll: true,
      }),
      columns: [
        new TextColumnConfig({
          dataField: "name",
          caption: "Наименование",
          validationRules: [requiredRule],
        }),

        new IntegerColumnConfig({
          dataField: "length",
          caption: "Длина (мм)",
          min: 0,
          validationRules: [requiredRule],
        }),

        new IntegerColumnConfig({
          dataField: "width",
          caption: "Ширина (мм)",
          min: 0,
          validationRules: [requiredRule],
        }),

        new IntegerColumnConfig({
          dataField: "height",
          caption: "Высота (мм)",
          min: 0,
          validationRules: [requiredRule],
        }),

        new DecimalColumnConfig({
          dataField: "loadCapacity",
          caption: "Грузоподъемность (кг)",
          hideFraction: true,
          decimalPoints: 0,
          min: 0,
          step: 1,
          validationRules: [requiredRule],
        }),

        new IntegerColumnConfig({
          dataField: "centerHeight",
          caption: "Высота в центре (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new IntegerColumnConfig({
          dataField: "doorWidth",
          caption: "Ширина дверного проема (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new IntegerColumnConfig({
          dataField: "doorThickness",
          caption: "Толщина дверного проема (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new IntegerColumnConfig({
          dataField: "doorHeight",
          caption: "Высота дверного проема (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new DecimalColumnConfig({
          dataField: "weight",
          caption: "Вес порожнего (кг)",
          hideFraction: true,
          decimalPoints: 0,
          min: 0,
          step: 1,
          validationRules: [requiredRule],
        }),

        new IntegerColumnConfig({
          dataField: "heightCenterMass",
          caption: "Высота порожнего (мм)",
          min: 0,
          validationRules: [requiredRule],
        }),
        new IntegerColumnConfig({
          dataField: "heightOverLevelRailHead",
          caption: "Высота уровня настила пола (мм)",
          readOnly: true,
          editVisible: false,
        }),
        new CatalogueColumnConfig({
          dataField: "gapDescription",
          caption: "Отступы (мм)",
          modelIdField: "gapId",
          modelDescField: "gapDescription",
          keyField: "id",
          displayField: "gapDescription",
          displayDescField: "name",
          catalogueComponentName: "catalogues/GapCatalogue/GapCatalogue",
          descriptionColumnName: "gapDescription",
          validationRules: [requiredRule],
        }),

        new CatalogueColumnConfig({
          dataField: "displacementDescription",
          caption: "Смещение",
          modelIdField: "displacementId",
          modelDescField: "displacementDescription",
          keyField: "id",
          displayField: "displacementDescription",
          displayDescField: "name",
          catalogueComponentName:
            "catalogues/DisplacementCatalogue/DisplacementCatalogue",
          descriptionColumnName: "displacementDescription",
          validationRules: [requiredRule],
        }),

        new IntegerColumnConfig({
          dataField: "planCount",
          caption: "Кол-во (шт)",
          min: 0,
          validationRules: [requiredRule],
        }),

        // new DecimalColumnConfig({
        //   dataField: "totalLoadCapacity",
        //   caption: "Общая грузоподъемность (т)",
        //   decimalPoints: 1,
        //   readOnly: true,
        // }),

        // new BooleanColumnConfig({
        //   dataField: "isSelectedForPlanning",
        //   caption: "Выбран для планирования",
        //   //disabled: true,
        // }),
      ],
      selectionConfig: new TableSelectionConfig({
        mode: "multiple",
        selectAllMode: "allPages",
        showCheckBoxesMode: "none",
      }),
      pageSizes: [10, 20, 50, 100],
    };
  },
  created() {
    if (this.selectMode) {
      this.editingConfig = this.selectModeEditingConfig;
    }
    if (this.gridHeight) {
      this.designConfig.height = this.gridHeight;
    }

    this.setColums();
  },
});
